import Button from "components/Button";
import { Helmet } from "react-helmet";

import PageTitle from "components/PageTitle";
import { basics } from "config";




const Catering = () => {
  return (
    <article className="w-full">
      <PageTitle>Stravování</PageTitle>

      <Helmet>
        <title>Stravování | {basics.appTitle}</title>
        <meta property="og:title" content="Stravování" />
      </Helmet>

      <div className="content-block">
        <h2>Kam na jídlo? Tady máte pár tipů ↓</h2>

<ul>
<li>
<b><a href="https://www.legendazlin.cz/">Legenda steak house</a></b>
<br/>v přízemí Interhotelu Zlín<br/>&nbsp;
</li>

<li>
<b>Pizzeria Bowling Bar</b>
<br/>vedle hlavního sálu<br/>&nbsp;
</li>

<li>
<b><a href="https://www.bistrovalachy.cz/">Bistrotéka Valachy</a></b>
<br/>190 metrů pěšky<br/>&nbsp;
</li>

<li>
<b><a href="https://nepalska-restaurace-makalu.cz/zlin/home/">Makalu</a></b>
<br/>(Nepal / Indie) - 220 metrů pěšky<br/>&nbsp;
</li>

<li>
<b><a href="https://hasicarnanovesta.hasicarnapubs.cz/">Hasičárna Novesta</a></b>
<br/>500 metrů pěšky<br/>&nbsp;
</li>

<li>
<b><a href="https://www.prasad.cz/">Prašád</a></b>
<br/>(vege) - 750 metrů pěšky<br/>&nbsp;
</li>

<li>
<b>McDonald's</b>
<br/>900 metrů pěšky<br/>&nbsp;
</li>

<li>
<b><a href="https://www.pranaya.cz/">Pranaya</a></b>
<br/>(vege) - 1 000 metrů pěšky
</li>
</ul>

<p>… a celá řada dalších restaurací a kaváren v okolí, ke kterým vás navede například Google 🙂
</p>

      </div>


      {/*
      <Button
              className="md:text-xl btn--to-blue-300 mt-8 mb-8"
              bodyClassName="whitespace-no-wrap"
              color="black"
              icon="ico--chevron-right"
              href={mapa_pdf}
              target="_blank"
            >
              Mapa restaurací v okolí konání workshopů
      </Button>
*/}

    </article>
  );
};

export default Catering;
