import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import classNames from "classnames";

import Button from "components/Button";
import PageTitle from "components/PageTitle";
import RegistrationForm from "components/RegistrationForm";
import { api, appurl, basics } from "config";

const resolveAudience = (keycloak) => {
  if (!keycloak.authenticated) {
    return false;
  }

  return true;
};

const Registration = () => {
  const { keycloak } = useKeycloak();
  const [questions, setQuestions] = useState();
  const [audience, setAudience] = useState(resolveAudience(keycloak));
  const [formData, setFormData] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState();
  const [submitDuplicity, setSubmitDuplicity] = useState(false);
  const [registrationFormRenderKey, setRegistrationFormRenderKey] = useState(0);
  const [response, setResponse] = useState("");
  //const [responseTotal, setResponseTotal] = useState(0);

  useEffect(() => {
    const doFetch = async () => {
      const [allQuestions] = await Promise.all([
        fetch(`${api.baseUrl}/questions`).then((r) => r.json()),
      ]);

      setQuestions(allQuestions);

//      const [allProducts] = await Promise.all([
//        fetch(`${api.baseUrl}/products`).then((r) => r.json()),
//      ]);

//      setProducts(allProducts);
    };

    doFetch();
  }, []);

  const submit = async () => {
    setSubmitting(true);
    setSubmitError(null);
    setSubmitDuplicity(false);

    try {
      const payload = formData;

      payload.token = keycloak.token;

      const resp = await fetch(`${api.baseUrl}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!resp.ok) {
        const error = new Error(resp.statusText);
        error.response = resp;
        throw error;
      }
      console.log(JSON.stringify(resp));
      const odpoved = await resp.json();
      console.log(JSON.stringify(odpoved));
      setResponse(odpoved);
      setSubmitError(null);
      setSubmitted(true);
    } catch (err) {
      console.error(err);

      const resp = await err.response.json();

      if (resp.errors.filter((err) => err.message === "Duplicity").length > 0) {
        setSubmitDuplicity(true);
      } else {
        setSubmitError(err);
      }

      setSubmitted(false);
    } finally {
      setSubmitting(false);
    }
  };

  const resetAudience = () => {
    setAudience(undefined);
    setFormData(undefined);
    setRegistrationFormRenderKey(registrationFormRenderKey + 1);
  };

  return (
    <article className="space-y-8 w-full">
      <PageTitle>Registrace</PageTitle>

      <Helmet>
        <title>Registrace | {basics.appTitle}</title>
        <meta property="og:title" content="Registrace" />
      </Helmet>


      {!submitted && (
        <div className="space-y-8">
          {!audience && (
            <>
                <p>
                Pokud se chcete zúčastnit {basics.appTitle}, musíte se přihlásit Pirátskou identitou a vyplnit registrační formulář.
                </p>

                <p>
                <Button
                  className="btn--black max-w-full sm:max-w-xs lg:max-w-full"
                  bodyClassName="whitespace-no-wrap"
                  color="black"
                  routerTo="/registrace-vip"
                  onClick={() => keycloak.login()}
                >
                  Pirátská identita
                </Button>
                </p>
            </>
          )}

          {audience && (
              <div>
                  <>
                    <p>
                      Registrujete se jako{" "}
                      <strong>{keycloak.tokenParsed.name}</strong>.
                      Pokud se snad chcete přihlásit pod jinou identitou, je
                      nutné se nejprve{" "}
                      <button className="underline" onClick={keycloak.logout}>
                        odhlásit
                      </button>
                      .
                    </p>
                  </>
              </div>
          )}

          {audience && questions && (
            <>
              <RegistrationForm
                key={registrationFormRenderKey}
                fixedName={keycloak.tokenParsed.name}
                fixedEmail={keycloak.tokenParsed.email}
                showOrganization={false}
                showMedia={false}
                showQuestions={true}
                showLunch={false}
                showParties={true}
                showPrintouts={false}
                showDonation={true}
                questions={questions}
                onSubmit={setFormData}
                className={classNames({ hidden: !!formData })}
              />
              {formData && (
                <div className="alert alert--grey-125 leading-normal items-center space-x-4">
                  <span className="ico--checkbox-checked text-3xl" />
                  <p>
                    Hotovo, vyplněno! Něco nesedí? Tak to{" "}
                    <button
                      className="underline"
                      onClick={() => setFormData(undefined)}
                    >
                      můžete upravit
                    </button>
                    .
                  </p>
                </div>
              )}
            </>
          )}

          {formData && (
            <>
              <div className="space-y-4">
                <p className="leading-normal">
                  Nyní už jen zbývá vše potvrdit. Pokud vše souhlasí, klikněte
                  na tlačítko níže.
                </p>
                {submitError && (
                  <p className="alert alert--error leading-normal">
                    Je nám líto, ale při odesílání došlo k nějakému problému,
                    zkuste to prosím ještě jednou.
                  </p>
                )}
                {submitDuplicity && (
                  <p className="alert alert--dark leading-normal">
                    <span>
                      Zdá se, že{" "}
                      <strong>registraci pro váš email již máme</strong>. Pokud
                      si přejete registraci nějak upravit, kontaktujte prosím
                      někoho{" "}
                      <Link to="/organizatori" className="underline">
                        z organizátorů
                      </Link>
                      .
                    </span>
                  </p>
                )}
                <Button
                  className="text-lg max-w-full col-span-1"
                  bodyClassName="whitespace-no-wrap"
                  color="black"
                  type="submit"
                  loading={submitting}
                  onClick={submit}
                >
                  Dokončit registraci
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      {submitted && response.total === "0.00" && (
        <div className="alert alert--blue-300 leading-normal space-x-4">
          <span className="ico--checkbox-checked text-3xl" />
          <div>
            <h2 className="head-heavy-sm mb-2">
              Vaše registrace byla úspěšná, těšíme se naviděnou!
            </h2>
            <p>
              <strong>Pozor! Systém nezasílá potvrzovací e-mail se vstupenkou.</strong><br/>
              E-mail s odkazem ke stažení vstupenek, které budete
              potřebovat při vstupu do objektu jednacího sálu obdržíte později.
              Mějte prosím strpení, k odeslání odkazu <strong>může dojít až za několik dní</strong>,
              některé registrace totiž musíme osobně zkontrolovat.
            </p>
          </div>
        </div>
      )}
      {submitted && response.total !== "0.00" && (
        <div className="alert alert--blue-300 leading-normal space-x-4">
          <span className="ico--checkbox-checked text-3xl" />
          <div>
            <h2 className="head-heavy-sm mb-2">
              Vaše registrace byla úspěšná, těšíme se naviděnou!
            </h2>
            <p>
              <strong>Pozor! Systém nezasílá potvrzovací e-mail se vstupenkou.</strong><br/>
              E-mail s odkazem ke stažení vstupenek, které budete
              potřebovat při vstupu do objektu jednacího sálu obdržíte později.
              Mějte prosím strpení, k odeslání odkazu <strong>může dojít až za několik dní</strong>,
              některé registrace totiž musíme osobně zkontrolovat.
            </p>
            <p>
              <br/>
              V registračním formuláři jste zvolil/a některé placené
              položky (např. stravování nebo tištěné materiály), případně chcete finančně přispět na pořádání akce.<br/>
              Následující pokyny použijte k zaplacení služeb, případně si je uložte k realizaci platby později.
              <br/><br/>
            </p>
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 content-block">
              <div>
                <p>
                  <strong>Pokyny k platbě:</strong><br/>
                  Číslo účtu: {response.payment_request.account}<br/>
                  Částka: {response.payment_request.amount} Kč<br/>
                  Variabilní symbol: {response.payment_request.vs}<br/>
                  Konstantní symbol: {response.payment_request.ks}<br/>
                  Specifický symbol: {response.payment_request.ss}<br/><br/>
                  Případně použijte QR kód k platbě z Vašeho mobilního telefonu.
                </p>
              </div>
              <div>
                <p>
                  <img src={appurl.baseUrl+response.payment_request.qr} alt="platba - qr kód" />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </article>
  );
};

export default Registration;
